<template>
  <div class="custom-advertising-container">
    <div class="custom-advertising-title">
      {{ translateTitle('自定义广告') }}
    </div>
    <div class="custom-advertising-info">
      {{ translateTitle('可进行幻灯，天气模块的配置与管理') }}
    </div>
    <div v-loading="loading" class="custom-advertising-list">
      <div
        v-for="(item, index) in sliderItemList"
        :key="index"
        class="custom-advertising-item"
        @click="handleClickList(item)"
      >
        <div class="custom-advertising-item-image">
          <el-image :src="item.image" />
        </div>
        <div class="custom-advertising-item-title">
          {{ translateTitle(item.name) }}
        </div>
        <div class="custom-advertising-item-desc">
          {{ translateTitle(item.subname) }}
          <!-- {{
            item.subname == '4.3之前版本首页'
              ? '4.3 Front page of previous versions'
              : translateTitle(item.subname)
          }} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import { getCustoCnmpm } from '@/api/operate'
  export default {
    data() {
      return {
        loading: false,
        sliderItemList: [],
        sliderList: Object.freeze([
          {
            image: require('@/assets/images/operate/operation_spread_slider.png'),
            desc: '4.3之前版本首页',
            path: 'version',
          },
          {
            image: require('@/assets/images/operate/operation_spread_slider.png'),
            desc: '频道页：附近频道',
            path: 'nearbyChannels',
          },
          {
            image: require('@/assets/images/operate/operation_spread_slider.png'),
            desc: '频道页：精彩推荐',
            path: 'recommend',
          },
          {
            image: require('@/assets/images/operate/operation_spread_slider.png'),
            desc: '频道页：内容',
            path: 'content',
          },
          {
            image: require('@/assets/images/operate/appindex_cnmp_weather.png'),
            desc: '发现页',
            path: 'find',
          },
        ]),
      }
    },
    created() {
      this.getCustoCnmpm()
    },
    methods: {
      translateTitle,
      handleClickList(item) {
        this.$router.push({
          path: `/operate/${item.path}`,
          query: {
            place: item.place,
            title: item.name,
          },
        })
      },
      getCustoCnmpm() {
        this.loading = true
        getCustoCnmpm({}).then((res) => {
          this.loading = false
          this.sliderItemList = res.data.map((item) => {
            const obj = this.sliderList.find((el) => el.desc == item.subname)
            return {
              ...item,
              image: obj.image,
              path: obj.path,
            }
          })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.custom-advertising';
  #{$base}-container {
    #{$base}-title {
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: bold;
    }
    #{$base}-info {
      font-size: 13px;
      color: $base-color-text-secondary;
    }
    #{$base}-list {
      display: flex;
      min-height: 100px;
      margin-top: 20px;
      #{$base}-item {
        margin-right: 50px;
        cursor: pointer;
        &-image {
          display: table-cell;
          width: 160px;
          height: 72px;
          text-align: center;
          vertical-align: middle;
          background-color: #f5f7f5;
        }
        &-title {
          display: block;
          width: 160px;
          margin-top: 8px;
          overflow: hidden;
          line-height: 20px;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &-desc {
          margin-top: 6px;
          font-size: 13px;
          line-height: 16px;
          color: $base-color-text-secondary;
          text-align: center;
        }
      }
    }
  }
</style>
